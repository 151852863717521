<template>
  <div class="page">
    <div class="max-w-2xl w-full mx-auto">
      <div class="flex gap-4">
        <w-input v-model="q" placeholder="Search..." clearable @onClear="() => { q = null }" />
        <w-buttons-group>
          <w-choice-select keep-label label="Status" :options="options" v-model="filter.status"></w-choice-select>
          <w-choice-select keep-label label="City" :options="cities" v-model="filter.city"></w-choice-select>
        </w-buttons-group>
      </div>
      <template v-if="Object.keys(filter).filter(k => filter[k])">
        <w-tag v-if="filter.status" :label="options.find(c => c.value == filter.status).label" @onClear="() => { filter.status = null }" />
        <w-tag v-if="filter.city" :label="cities.find(c => c.value == filter.city).label" @onClear="() => { filter.city = null }" />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: [
        { label: 'Matched', value: 'matched' },
        { label: 'Reconcile', value: 'reconcile' }
      ],
      cities: [
        { label: 'New York', value: 'newyork' },
        { label: 'Tokyo', value: 'tokyo' },
        { label: 'Shanghai', value: 'shanghai' }
      ],
      q: null,
      filter: {
        status: null,
        city: null
      }
    }
  },
  methods: {
  }
}
</script>